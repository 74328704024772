@media screen and (max-width:1199px) {

    .bangaMain img {
        display: none;
    }


    /*////APIE////*/

    .apie {
        flex-direction: column;
        align-items: center;
    }

    .apieTxt {
        width: 53vw;
    }

    .apieLeft {
        height: unset;
    }

    .apieRight {
        height: unset;
        margin-top: 40px;
    }

    .apieName {
        text-align: center;
    }

    .apieLeftTxtDiv {
        position: relative;
    }

    .fill-window {
        height: 100%;
    }

    /*////LOGO////*/

    .logoVarCont {
        height: unset;
    }

    .logoTop {
        flex-direction: column;
        align-items: center;
    }

    .logoTopRight {
        width: 70vw;
        margin: 20px 0;
    }

    .logoTxt {
        width: 70vw;
    }

    .logoTopLeft {
        width: 100%;
    }

    .logoName {
        font-size: 80px;
        margin: 0;
        padding-bottom: 10px;
    }

    .logoName2 {
        color: black;
        font-size: 80px;
        margin: 0;
    }

    .logoBot {
        flex-direction: column;
        align-items: center;
    }

    .logoBot img {
        margin: 20px 0;
    }

    .LogoMain {
        width: 30%;
        height: 600px;
    }

    .LogoMainMob {
        display: flex;
        width: 30%;
        justify-content: center;
        align-items: center;
    }

    .LogoMainCont {
        height: unset;
    }

    /*////SPALVOS////*/

    .spalvos2 {
        flex-direction: column-reverse;
    }

    .spalvosTxt {
        height: unset;
        width: 58%;
        margin-top: 30px;
        text-align: center;

    }

    .BobDylan {
        color: #1A1A70;
    }

    .Aretha {
        color: #BB0067;
    }

    .spalvosCont {
        padding-top: 10px;
        height: unset;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        background-color: #D58435;
        color: aliceblue;
    }

    .spalvosName {
        text-align: center;
        width: unset;
    }

    .color {
        position: relative;
    }

    .color img {
        width: 100%;
    }

    /*////TIPOGRAFIJA////*/

    .tipgrafija {
        margin-top: -5px;
    }


    .tipografijaCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 800px;
    }

    .tipografijaTxt1 {
        left: 22px;
        bottom: 307px;
        width: 88%;
        padding: 0px;
        padding: 20px 0 51px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    .tipografijaTxt2 {
        left: 67px;
        bottom: -48px;
        width: 70%;
        padding: 0px;
        padding: 20px 20px 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
    }

    .tipografijaTxtName {
        font-size: 38px;
        display: flex;
        justify-content: center;
    }

    .tipografijaName {
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px 0 20px 0;
        font-size: 70px;
        display: flex;
        justify-content: center;
    }

    .tipografijaTxtCont {
        display: flex;
        justify-content: center;
        width: 64%;
    }

/*//////VERSLO////*/

    .verslo {
        flex-direction: column;
        margin-top: 27px;
        height: 800px;
    }

    .verslo1 {
        width: 87%;
        bottom: 74px;
        left: 10px;
    }

    .verslo1 img {
        width: 80%;
        top: 38px;
    }

    .versloName {
        top: -9px;
        RIGHT: 30px;
        width: 83%;
        padding: 30px 0;
        font-size: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #00896F;
    }

    .verslo2 img {
        top: 87px;
        right: 5px;
        width: 61%;
        padding: 20px;
    }

    .verslo2 {
        width: 100%;
        background-color: #BB0067;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}