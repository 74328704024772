:root {
  --main-background: #F4E8D3;
  --main-blue: #1A1A70;
  --main-green: #00896F;
  --main-pink: #BB0067;
  --main-orange: #DA3716;
  --main-yellow: #D58435;
}

.font-face-gm {
  font-family: "Prohibition-Regular.ttf";
}

body {
  background: var(--main-background);
}

.scrollhost {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollhost::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

.h1 {
  font-size: 100px;
}

/*////LOGO ANIMACIJOS////*/

.LogoMainCont {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoMain {
  width: 10%;
}

/*///5 LINIJU ILIUSTRACIJA///*/

.bangaMain img {
  width: 100%;
  position: absolute;
  height: 1800px;
}

.bangaMob {
  display: none;
}


/*////APIE/////*/

.homeCont {
  z-index: 1;
}

.fill-window {
  height: 900px;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home {
  display: flex;
  flex-direction: column;
}

.banga {
  width: 100%;
}

.apie {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 13vw;
}

.apieLeft {
  width: 50vw;
  display: flex;
  justify-content: center;
  height: 800px;
  align-items: flex-end;
}

.apieRight {
  width: 50vw;
  display: flex;
  justify-content: center;
  /* height: 900px; */
  margin-top: 200px;
}

.apieName {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  margin-bottom: 20px;
}

.apieTxtCont {
  display: flex;
  flex-direction: column;
  width: 30vw;
  font-family: "NunitoSans-Regular";
  font-size: 20px;
  align-items: center;
}

.apieTxt {
  width: 20vw;
  word-break: keep-all;
}

ul.apieLi {
  list-style-image: url('assets/dot1.png');
}

.BobDylan {
  color: var(--main-orange);
  text-decoration: none;
  cursor: pointer;
}

.Aretha {
  color: var(--main-green);
  text-decoration: none;
  cursor: pointer;
}

.apieLeftTxtDiv {
  display: flex;
  flex-direction: column;
}


/*////////NAV///////*/

.mobileNav {
  display: flex;
  align-items: center;
  position: relative;
}

.ham img {
  position: absolute;
  left: 10px;
  padding: 20px;
}

.ham {
  z-index: 11;
}

.navBar {
  width: 100%;
  position: relative;
  display: flex;
}

.mobileNavigationList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: var(--main-background);
  position: fixed;
  justify-content: center;
}

.mobileClose {
  position: absolute;
  left: 30px;
  font-size: 20px;
}

.navBar {
  display: flex;
  position: relative;
  z-index: 2;
  position: fixed;
  z-index: 11;
  height: 10px;
}

.navItem {
  text-decoration: none;
  font-family: "Prohibition-Regular";
  font-size: 50px;
  color: black;
  padding: 7px;
}

.navItem:hover {
  color: var(--main-blue);
}

.navItem1 {
  text-decoration: none;
  font-family: "Prohibition-Regular";
  font-size: 50px;
  color: black;
  padding: 7px;
}

.navItem1:hover {
  color: var(--main-green);
}

.navItem2 {
  text-decoration: none;
  font-family: "Prohibition-Regular";
  font-size: 50px;
  color: black;
  padding: 7px;
}

.navItem2:hover {
  color: var(--main-pink);
}


.navItem3 {
  text-decoration: none;
  font-family: "Prohibition-Regular";
  font-size: 50px;
  color: black;
  padding: 7px;
}

.navItem3:hover {
  color: var(--main-orange);
}


.navItem4 {
  text-decoration: none;
  font-family: "Prohibition-Regular";
  font-size: 50px;
  color: black;
  padding: 7px;
}

.navItem4:hover {
  color: var(--main-yellow);
}

/*/////LOGO/////*/

.logo {
  display: flex;
  flex-direction: column;
}

.logoTop {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.logoTopLeft {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DA3716;
}

.logoTopRight {
  width: 50vw;
  display: flex;
  justify-content: center;
}

.logoBot {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
}

.logoBot1 img {
  width: 110%;
}

.logoName {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  position: relative;
  color: #F4E8D3;
  margin: 0;
}

.logoName2 {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  position: relative;
  margin: 0;
}

.logoTxt {
  width: 40vw;
  font-size: 20px;
}

ul.logoTxt2 {
  list-style-image: url('assets/dot2.png');
}

/*//////SPALVOS////*/

.color {
  position: absolute;
}

.spalvos2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.color img {
  height: 100%;
}

.spalvosCont {
  z-index: 2;
  justify-content: space-around;
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.spalvosName {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.spalvosNameMob {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  width: 30%;
  display: none;
  justify-content: flex-end;
}

.spalvosTxt {
  z-index: 3;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 70px;
  font-size: 20px;
  font-family: "NunitoSans-Regular";
}

/*///TIPOGRAFIJA////*/

.tipografijaName {
  font-family: "Prohibition-Regular" !important;
  font-size: 100px;
  background-color: #D58435;
  width: 600px;
  padding: 20px 20px 30px 20px;
  display: flex;
  position: absolute;
  bottom: 50px;
  left: -640px;
  z-index: 2;
  justify-content: center;
  align-items: center;
}

.tipografijaTxt1 {
  font-family: "Prohibition-Regular" !important;
  background-color: #DA3716;
  color: #1A1A70;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 450px;
  position: absolute;
  left: -476px;
  bottom: 200px;
  z-index: 1;
}

.tipografijaTxt2 {
  font-family: "NunitoSans-Regular";
  background-color: #1A1A70;
  color: #D58435;
  padding: 50px 50px 50px 100px;
  display: flex;
  flex-direction: column;
  width: 62vh;
  position: absolute;
  bottom: 100px;

}

.tipografijaTxtName {
  font-size: 58px;
  margin: 0;
}

.tipografijaTxtCont {
  font-size: 20px;
  margin-top: 30px;
}

.tipografijaCont {
  height: 800px;
  display: flex;
  position: relative;
}

.tipgrafija {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*///LOGO SKIRTINGAIS FONAIS///*/

.LogoVar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logoVarCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;

}


@keyframes logovar2ani {
  0% {
    background-color: #D5A575;
  }

  25% {
    background-color: #D56A55;
  }

  50% {
    background-color: #7171D5;
  }

  75% {
    background-color: #40D5B9;
  }
}

@keyframes logovar2ani2 {
  0% {
    background-color: #D58435;
  }

  25% {
    background-color: #DA3716;
  }

  50% {
    background-color: #12124D;
  }

  75% {
    background-color: #00896F;
  }
}

.move-me-1 {
  animation: logovar2ani 5s steps(1, end) infinite;
}

.move-me-2 {
  animation: logovar2ani2 5s steps(1, end) infinite;
}

.logovar1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #BB0067;
}

.logovar2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background-color: #D58435;
}

/*////VERSLO DOVANOS////*/

.verslo {
  height: 800px;
  position: relative;
  margin-top: 100px;
  display: flex;
  /* flex-direction: row; */
}

.verslo1 img {
  padding: 30px;
  width: 30vw;
  top: 10vw;
}

.verslo1 {
  background-color: #D58435;
  position: absolute;
  width: 70vw;
}

.versloName {
  width: 50vw;
  z-index: 10;
  font-family: "Prohibition-Regular" !important;
  font-size: 8vw;
  position: absolute;
  right: 0;
  top: 4.5vh;
  background-color: #1A1A70;
  padding: 30px;
  display: flex;
  align-items: center;
  color: #F4E8D3;
}

.verslo2 img {
  position: absolute;
  width: 17%;
  right: 300px;
  top: 13vw;
  padding: 70px 150px 60px 150px;
  background-color: #BB0067;
}

.menuBanga {
  position: absolute;
}

.menuBangaCont {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/*//////FOOTERIS//////*/

.footer {
  height: 125px;
  width: 100%;
}

.footerMob {
  height: 125px;
  width: 100%;
  display: none;
}

.footer1 {
  height: 25px;
  background-color: #1A1A70;
  display: flex;
  justify-content: center;
  color: #F4E8D3;
  font-family: "NunitoSans-Regular";
  align-items: center;
}

.footer2 {
  height: 25px;
  background-color: #00896F;
  display: flex;
  justify-content: center;
  color: #F4E8D3;
  font-family: "NunitoSans-Regular";
  align-items: center;
  font-size: 10px;
}

.footer3 {
  height: 25px;
  background-color: #BB0067;
  display: flex;
  justify-content: center;
  color: #F4E8D3;
  font-family: "NunitoSans-Regular";
  align-items: center;
  font-size: 10px;
}

.footer4 {
  height: 25px;
  background-color: #DA3716;
  display: flex;
  justify-content: center;
  color: #F4E8D3;
  font-family: "NunitoSans-Regular";
  align-items: center;
  font-size: 10px;
}

.footer5 {
  height: 25px;
  background-color: #D58435;
  display: flex;
  justify-content: center;
  color: #F4E8D3;
  font-family: "NunitoSans-Regular";
  align-items: center;
  font-size: 10px;
}